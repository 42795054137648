WebFontConfig = {
    google: {
        families: [
            'Domine:400,700:latin,latin-ext',
            'Dosis:400,500,600,700:latin,latin-ext'
                //'Patrick+Hand+SC::latin,latin-ext'
        ]
    }
};

